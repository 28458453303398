<template>
  <div id="gifteelist-view">
    <section class="bg-light">
      <main></main>
      <div class="col-md-12">
        <div class="findgft">
          <div class="row">
            <div class="col-md-10">
              <router-link
                to="/wishlist"
                class="sm_megamenu_head sm_megamenu_drop"
                id="sm_megamenu_286"
                href=""
              >
                <h1 class="display-4 pt-2">WishList</h1></router-link
              >
            </div>
            <div class="col-md-2">
              <b-form-radio-group
                id="btn-radios-2"
                v-model="wishlist_status"
                :options="chk_options"
                button-variant="outline-danger"
                size="sm"
                name="radio-btn-outline"
                buttons
                @change="changeStatus(wishlist_status)"
              ></b-form-radio-group>
            </div>
          </div>
          <div class="row mb-3" style="margin: 0 auto;">
            <span class="" style="font-size:15px;background-color:#ddd;">
              <h6>
                Your wishlist will be visible to all your family <br />
                and friends. You can optionally make it Private
              </h6>
            </span>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <img
            src="@/assets/site/images/loader.gif"
            v-if="loader === true"
            class="img-fluid"
            alt
          />
        </div>
      </div>
      <div class="container-fluid pt-5"></div>
      <p v-if="imageshow">
        <span class="d-flex justify-content-center"
          ><img
            class="img-fluid text-center"
            src="@/assets/site/images/Group1.svg"
            alt=""
        /></span>
      </p>
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-md-4"
            style="max-height: 650px; overflow-y: scroll; overflow-x: hidden"
          >
            <ul>
              <li
                v-for="occasion_list in occasion_lists"
                class="sm_megamenu_title ml-4 my-3"
                @click="showProduct(occasion_list)"
              >
                <h3 class="my-3">
                  <span class="col-md-3">
                    <img
                      src="@/assets/site/images/calendar.svg"
                      width="30"
                      height="30"
                      class="img-fluid"
                      alt=""
                    />
                  </span>
                  <span class="col-md-8">
                    {{ occasion_list.name }}
                  </span>
                </h3>
              </li>
            </ul>
          </div>
          <div class="col-md-8">
            <div class="container" ref="toolbarChat">
              <wishlist-view
                v-if="show"
                v-bind:type="type"
                v-bind:wishlistproducts="wishlistproducts"
                v-bind:updateProducts="updateProducts"
              ></wishlist-view>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <hr />
      </div>
    </section>

    <!-- End Footer -->
  </div>
</template>
<script>
import WishlistView from "@/views/site/user/WishlistView";

export default {
  name: "MyoccasionsView",
  metaInfo: {
    title: "My Occasions",
    meta: [
      {
        name: "description",
        content:
          "Discover and manage your Wishlist with ease! View, modify, and share your favorite items while keeping your wishlist private or public.",
      },
      {
        name: "keywords",
        content:
          "wishlist, manage wishlist, gift ideas, product viewing, private wishlist, public wishlist, family and friends, occasion lists, wishlist products, user-friendly interface, online shopping, wishlist management, gift selection, easy navigation, product categories",
      },
    ],
  },
  data() {
    return {
      loader: false,
      errors: [],
      pages: [],
      contacts: [],
      wishlistproducts: [],
      selectedOccasion: null,
      type: "user",
      occasion_lists: [],
      imageshow: false,
      wishlist_status: null,
      show: false,
      chk_options: [
        { text: "Public", value: "Public" },
        { text: "Private", value: "Private" },
      ],
    };
  },
  components: { WishlistView },
  created() {
    this.getWishlistProducts();
  },
  methods: {
    updateProducts(index) {
      this.$delete(this.wishlistproducts, index);
      if (this.wishlistproducts.length === 0) {
        const occasionIndex = this.occasion_lists.findIndex(
          (occasion) => occasion.name === this.selectedOccasion.name
        );
        this.$delete(this.occasion_lists, occasionIndex);
        this.selectedOccasion = null;
        this.imageshow = false;
      }
    },
    getWishlistProducts() {
      this.errors = [];
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var user_id = userdata_array.user.id;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url =
        process.env.VUE_APP_URL + "customer/userwishlist/getwishlistProducts";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          wishlist_type: "User",
          user_id: user_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.occasion_lists = data.occasion_lists;
          this.wishlistproducts = data.wishlist;
          this.wishlist_status = data.wishlist_status;
          if (this.wishlistproducts.length == 0) {
            this.imageshow = true;
          }
        });
    },
    showProduct(occasion_list) {
      this.show = true;
      this.selectedOccasion = occasion_list;
      this.wishlistproducts = occasion_list.products;
      window.scrollTo(0, 0);
    },

    changeStatus(wishlist_status) {
      this.errors = [];
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var user_id = userdata_array.user.id;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url = process.env.VUE_APP_URL + "customer/changeWishlistStatus";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          user_id: user_id,
          wishlist_status: wishlist_status,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            this.$swal.fire("Good to Go", data.message, "success");
          } else {
            this.$swal.fire("Error!", data.message, "error");
          }
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
  },
};
</script>
